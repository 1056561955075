<template>
    <div class="account">
        <div class="userTitle">
            <span>{{ $t('bian-geng-shou-ji-hao') }}</span>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: adminPath }">{{ $t('guan-li-zhong-xin') }}</el-breadcrumb-item>

                <el-breadcrumb-item :to="{ path: '/accountInfo' }">{{ $t('zhang-hu-yu-an-quan') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('bian-geng-shou-ji-hao') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="main" v-if="step !== 2">
            <div class="step">
                <el-steps :active="step" align-center>
                    <el-step :description="$t('yan-zheng-dang-qian-shou-ji-hao')"></el-step>
                    <el-step :description="$t('bang-ding-xin-shou-ji-hao')"></el-step>
                    <el-step :description="$t('wan-cheng')"></el-step>
                </el-steps>
            </div>

            <el-form
                label-width="120px"
                :rules="rules"
                size="small"
                :model="form"
                style="width: 480px;"
                ref="form"
                hide-required-asterisk
            >
                <el-form-item :label="$t('dang-qian-bang-ding-shou-ji')" prop="phone">
                    <Phone
                        v-model="form.phone"
                        readonly
                        :select="false"
                        :defaultPhone="defaultPhone"
                        v-if="step == 0"
                    ></Phone>
                    <Phone
                        v-else-if="step == 1"
                        v-model="form.phone"
                        :placeholder="$t('qing-shu-ru-xin-de-shou-ji-hao')"
                        small
                    ></Phone>
                </el-form-item>
                <el-form-item class="code" :label="`* ${$t('shou-ji-yan-zheng-ma')}`" prop="code">
                    <div class="codeInput input2">
                        <el-input v-model="form.code" :placeholder="$t('shu-ru-yan-zheng-ma')" maxlength="6">
                        </el-input>
                        <el-button type="warning" plain @click="sendMsg" :disabled="isSend">
                            {{ isSend ? `${$t('yi-fa-song')}(${senNum}S)` : $t('huo-qu-yan-zheng-ma') }}</el-button
                        >
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button class="submit" type="warning" @click="onSubmit" :loading="loading">确认</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="success" v-else>
            <img src="../../assets/popup_icon_success_colour.png" alt="" />
            <div class="text">
                {{ $t('shou-ji-geng-huan-cheng-gong-ni-ke-yi-shi-yong-xin-de-de-shou-ji-hao-jin-hang-deng-lu-le') }}
            </div>
            <el-button class="submit" type="warning" @click="$router.go(-1)" :loading="loading">
                {{ $t('fan-hui') }}
            </el-button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import formPage from '../../mixins/formPage';
import Phone from '../../components/PhoneInput';
import { backPhone, verifySms } from '../../utils/Sms';
export default {
    name: 'manage',
    mixins: [formPage],
    data() {
        return {
            form: {
                code: '',
                phone: ''
            },
            defaultPhone: '',
            step: 0
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    mounted() {
        this.defaultPhone = this.userInfo.phone;
    },
    methods: {
        submit() {
            if (this.step == 0) {
                verifySms(this.form.phone, this.form.code).then(() => {
                    this.step = 1;
                    this.$nextTick(() => {
                        this.$refs.form.resetFields();
                    });
                });
            } else {
                this.$http
                    .post('/user/changePhone?phone=' + backPhone(this.form.phone), {
                        code: this.form.code
                    })
                    .then(res => {
                        this.step = 2;
                        this.$store.dispatch('getUserInfo');
                    })
                    .catch(e => {
                        this.$message.warning(e.error);
                    });
            }
        }
    },
    components: {
        Phone
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.userTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    padding: 0;
    border-bottom: 1px solid #bcc1cc;
    margin: 0 20px;
}

.main {
    padding: 20px;
}
.submit {
    &.el-button {
        padding: 0 20px;
        line-height: 40px;
        min-width: 160px;
        margin-top: 32px;
    }
}

.codeInput {
    display: flex;
    align-items: center;
    .el-button {
        padding: 11px 16px;
        // min-width: 142px;
        box-sizing: border-box;
    }
}

.rule {
    margin-top: 50px;
    margin-bottom: 40px;
    .el-checkbox {
        color: #c0c4cc;
    }
}

.step {
    width: 460px;
    margin: 50px auto;
}

.success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px;
    img {
        width: 100px;
        height: 100px;
        display: block;
    }
    .text {
        font-size: 14px;
        color: #878d99;
        line-height: 20px;
        margin: 40px 0 60px;
    }
}
</style>
<style lang="scss">
@import '../../styles/variables';
.code {
    .el-input-group__append {
        background-color: transparent;
        overflow: hidden;
        border-width: 0;
        box-sizing: border-box;
        height: 40px;
        .el-button {
            background-color: transparentize($--color-warning, 0.9);
            color: $--color-warning;
            // box-sizing: border-box;
            padding: 0px 20px;
            line-height: 38px;
            height: 38px;
            display: block;
            border: 1px solid $--color-warning;
            &:hover {
                background-color: $--color-warning;
                color: $--color-white;
            }

            &.is-disabled {
                background-color: transparentize($--color-info, 0.9);
                color: $--color-info;
                box-shadow: inset 0 0 0 1px $--color-info;
            }
        }
    }
}

.account .step {
    .el-step__icon.is-text {
        border-width: 0px;
    }
    .el-step__head.is-wait {
        font-size: 12px;
        color: #fff;

        .el-step__icon.is-text {
            background-color: #dcdfe6;
        }
    }
    .el-step__head.is-process {
        color: #fff;
        .el-step__icon.is-text {
            background-color: $--color-warning;
        }
    }

    .el-step__head.is-finish {
        color: #fff;
        .el-step__icon.is-text {
            background-color: $--color-warning;
        }
        .el-step__line {
            background-color: $--color-warning;
        }
    }
    .el-step__line {
        height: 1px;
    }

    .el-step__description.is-finish {
        color: $--color-warning;
    }

    .el-step__main {
        margin-top: 15px;
    }
}
</style>
